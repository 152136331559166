<template>
    <HeroPage v-if="pageData" :data="pageData" />

    <VideoGridFilterCollection :paging="fetchDataParams.paging"
        :collectionFilter="['Exercises', 'Age group', 'Level', 'Available players']" :searchParams="searchParams" />

    <PageLayoutBuilder v-if="pageData" :data="pageData" />
</template>

<script>
import HeroPage from "@/components/HeroPage";

import PageLayoutBuilder from "@/components/PageLayoutBuilder";

import VideoGridFilterCollection from "@/components/VideoGridFilterCollection";

import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

export default {
    name: "VideoCatTrainingExercises",
    inject: ["isAuthenticated"],
    // metaInfo() {
    //     return {
    //         title:
    //             "Home | LEAD Hockey",
    //         description:
    //             "",
    //     };
    // },
    data() {
        return {
            pageData: null,
            fetchDataParams: {
                paging: {
                    pageNumber: 1,
                    pageSize: 6,
                },
            },
            searchParams: [
                {
                    type: "Skill Categories",
                    value: "3204e7b7-264a-4a96-8230-b6e5f8bf068e",
                },
                {
                    type: "Video Type",
                    //TEST
                    // value: "0e8f0731-8a0c-4685-be3d-a5f3ccb5dd99",

                    //LIVE
                    value: "c80d1369-3861-4e0c-8278-3c29605b2c03"
                },
            ],
        };
    },
    components: {
        HeroPage,
        PageLayoutBuilder,
        VideoGridFilterCollection
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 50);
        },
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "video-training-exercises").then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
